.carousel
	background-color:#000
	.carousel-image-container
		img
			// mix-blend-mode: overlay
			opacity:0.3
	.carousel-caption
		// bottom: auto !important
		bottom: 50px
		top: 50px
		// background-color: rgba(0,0,0,0.3)
		// border:1px outset rgba(0,0,0,0.6)
		// border-radius: $size-m
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		// padding: $size-m
		.btn
			width: 50%